import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentCard  from "../components/contentCard"

const IndexPage = () => {
  const images = useStaticQuery(graphql`
    query {
      people: file(relativePath: { eq: "people.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      person: file(relativePath: { eq: "person.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      meeting: file(relativePath: { eq: "room.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Credos Oy" />
      <Container>
        <CenteredWrapper>
          <TitleHeading>
            Credos Oy on vuonna 2002 perustettu yhtiö, jonka missio on auttaa
            yrittäjiä yritystoimintaan liittyvien haasteiden voittamisessa.
            Teemme toimeksiantomme luottaen vankkaan osaamiseemme sekä laajaan
            yhteistyöverkostoomme.
          </TitleHeading>
        </CenteredWrapper>
        <ContentCardsContainer>
          <ContentCard
            title="Yrityksen strategian toteuttaminen saattaa vaatia yritysrakenteeseen
            tai omistukseen liittyviä muutoksia. Muutokset ovat monimutkainen
            kokonaisuus, jonka läpivienti edellyttää kokemusta ja monipuolista
            asiantuntemusta."
            imgProps={{
              fluid: images.person.childImageSharp.fluid,
              alt: "Mies katsoo ulos ikkunasta parvekkeella",
            }}
            paragraphs={[
              `Yritystoiminnan muutostilanteissa, olipa kyseessä yrityksen,
            liiketoiminnan tai yrityksen osan myynti taikka toisen yrityksen tai
            liiketoiminnan ostaminen, on järkevää turvautua ulkopuoliseen
            kokeneeseen asiantuntijaan.`,
              `Credos Oy:llä on laaja kokemus yrityskauppojen avustamisesta sekä
            muutostilanteisiin liittyvän yritystoiminnan arvonmäärityksen
            tekemisestä`,
            ]}
          />
          <ContentCard
            imgProps={{
              fluid: images.meeting.childImageSharp.fluid,
              alt: "Kokoushuone kuvattuna huoneen ulkopuolelta",
            }}
            title="Riittävä rahoitus on yrityksen keskeinen toimintaedellytys,
            välttämätön polttoaine. Tämä korostuu paitsi kasvun rahoittamisessa
            myös yrityksen muutostilanteissa."
            paragraphs={[
              `Riittävän rahoituksen varmistaminen on välttämätöntä
          yritystoiminnassa yrityksen kasvun varmistamiseksi. Lisäksi
          yrityksen muutostilanteisiin liittyy lähes aina rahoitukseen
          liittyviä ratkaistavia kysymyksiä.`,
              `Credos Oy pystyy auttamaan sekä oman pääoman ehtoisen että vieraan
          pääoman ehtoisen rahoituksen järjestämisessä.`,
            ]}
          />
          <ContentCard
            imgProps={{
              fluid: images.people.childImageSharp.fluid,
              alt: "Työporukka palaverissa",
            }}
            title="Oikeudenmukainen ansiotaso on yksi tärkeimmistä työviihtyvyyden
            tekijöistä. Palkitsemisella saadaan aikaan oikeudenmukainen ja
            suorituksen perusteella joustava ansiotaso yrityksen työntekijöille."
            paragraphs={[
              `Motivoitunut henkilöstö on yrityksen tärkein voimavara. Hyvän
            motivaation varmistamiseksi oikeudenmukainen henkilöstön
            palkitseminen on parhaita työkaluja.`,
              `Credos Oy:llä on yli 15 vuoden kokemus erilaisten
            palkitsemisjärjestelmien suunnittelussa, toteuttamisessa ja
            ylläpidossa.`,
            ]}
          />
        </ContentCardsContainer>
      </Container>
    </Layout>
  )
}

export default IndexPage

const Container = styled.section`
  max-width: 1250px;
  margin: 0 auto;
`
const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 3rem auto;
  max-width: 1000px;
  padding-bottom: 1rem;
  border-bottom: 1px solid grey;
  text-align: center;
`

const ContentCardsContainer = styled.section`
  margin-bottom: 5rem;
`
const TitleHeading = styled.h2`
  font-weight: normal;
  padding: 0 1rem;
`
